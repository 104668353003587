<template>
  <VSnackbar v-model="showSnackbar" bottom :timeout="0" class="menu-snackbar">
    You have unpublished changes.
    <BaseButton
      v-if="!loggedIn"
      class="ui"
      color="orange"
      outline
      @click="showAuthModal"
    >
      Login
    </BaseButton>
    <BaseButton
      v-else
      class="ui"
      color="orange"
      outline
      @click="publishSectionChanges"
    >
      Publish
    </BaseButton>
    <BaseButton class="ui" color="grey" flat @click="hideMenuSnackbar">
      Close
    </BaseButton>
  </VSnackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { VSnackbar } from '@vuetify'
export default {
  name: 'MenuSnackbar',
  components: { VSnackbar },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('editor', ['isMenuSnackbarVisible']),
    ...mapGetters('auth', ['loggedIn']),
    showSnackbar: {
      get() {
        return this.isMenuSnackbarVisible
      },
      set() {
        // nope
      },
    },
  }, // computed
  methods: {
    ...mapActions('sections', ['publishSectionChanges']),
    ...mapActions('editor', ['hideMenuSnackbar']),
    ...mapActions('auth', ['showAuthModal']),
  }, // methods
}
</script>
